import {
  find,
  findIndex,
  forEach,
  get,
  isArray,
  isEmpty,
  isNil,
  isString,
  map,
  set,
  sortBy,
} from "lodash";
import { Component, Vue } from "vue-property-decorator";
import type { DataTableHeader as DataTableHeaderVuetify } from "vuetify";
import hash from "object-hash";

export interface DataTableHeader extends DataTableHeaderVuetify {
  sortOrder?: number;
  translated?: boolean;
}

export interface DataTableSelect<T = any> {
  item: T;
  value: boolean;
}

export interface DataTableSelectAll<T = any> {
  items: Array<T>;
  value: boolean;
}

@Component
export default class DataTableMixin extends Vue {
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortOrder: 11 },
    {
      text: "active",
      value: "active",
      class: "mw-150",
      sortable: false,
      sortOrder: 12,
    },
  ];

  get sDataTableKey() {
    return hash(this.headers);
  }

  setDTHeaders(arHeaders: DataTableHeader[]) {
    this.headers = arHeaders;

    return this.mapDTHeaders();
  }

  addDTHeaders(arHeaders: DataTableHeader[], bMap = false) {
    forEach(arHeaders, (obItem: DataTableHeader) => this.addDTHeader(obItem));

    if (bMap) {
      this.mapDTHeaders();
    }

    return this;
  }

  addDTHeader(obHeader: DataTableHeader, bMap = false) {
    if (!this.hasDTHeader(obHeader.value)) {
      this.headers.push(obHeader);

      if (bMap) {
        this.mapDTHeaders();
      }
    }

    return this;
  }

  hasDTHeader(sValue: any, sKey: keyof DataTableHeader = "value"): boolean {
    return find(this.headers, { [sKey]: sValue }) !== undefined;
  }

  addDTActionsHeader() {
    return this.addDTHeader({
      text: "",
      value: "actions",
      align: "end",
      sortable: false,
      width: "100px",
      sortOrder: 999,
      translated: true,
    });
  }

  delDTHeader(sValue: string | string[]) {
    if (isArray(sValue)) {
      forEach(sValue, (sItem) => this.delDTHeader(sItem));
      return this;
    }

    if (isString(sValue) && this.hasDTHeader(sValue)) {
      const idx = findIndex(this.headers, (item) => item.value == sValue);
      if (idx !== -1) {
        this.headers.splice(idx, 1);
      }
    }

    return this;
  }

  mapDTHeaders() {
    this.headers = sortBy(this.headers, (item) => get(item, "sortOrder", 99));

    this.headers = map(this.headers, (item) => {
      if (isEmpty(item.text) || isNil(item.text)) {
        return item;
      }

      // Prevent double translation
      if (get(item, "translated") === true) {
        return item;
      }

      item.text = this.$t(item.text) as string;
      set(item, "translated", true);
      return item;
    });

    return this;
  }
}
